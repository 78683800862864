<template>
    <div class="h-100">
        <!-- <HeroArea /> -->
        <!-- <FeatureTwo /> -->
        <!-- <UseableTools /> -->
        <!-- <FeatureThree /> -->
        <!-- <ShortBanner /> -->
        <!-- <Faq /> -->
        <!-- <CallToAction/> -->
        <div class="full-height-layout d-flex align-items-center">
            <div class="coming-soon-content font-gordita">
                <h6 style="line-height: 1.5rem;">Mitigate VSCode Supply Chain Attacks</h6>
                <h1>Protect Your IDE from Malicious Extensions</h1>
                <div class="row">
                    <div class="col-lg-9 m-auto">
                        <p>Sign up for early access</p>
                    </div>
                </div>

                <form
                    action="https://gmail.us21.list-manage.com/subscribe/post?u=aaa2401da345dd39cdec27349&amp;id=aaa608812e&amp;f_id=003cd6e1f0"
                    method="post" target="_blank" autocomplete="off">
                    <input name="EMAIL" type="email" placeholder="Enter your email">
                    <input type="hidden" name="b_aaa2401da345dd39cdec27349_aaa608812e" tabindex="-1" value="">
                    <button>Sign Up</button>
                </form>

                <ul class="social-icon d-flex justify-content-center">
                    <li><a href="https://twitter.com/coderguard"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                    </li>
                    <li><a href="https://coderguard.canny.io/"><i class="fa fa-comments" aria-hidden="true"></i></a>
                    </li>
                </ul>

                <img src="~/assets/images/shape/179.svg" alt="" class="shapes shape-one">
                <img src="~/assets/images/shape/180.svg" alt="" class="shapes shape-two">
                <img src="~/assets/images/shape/181.svg" alt="" class="shapes shape-three">
                <img src="~/assets/images/shape/182.svg" alt="" class="shapes shape-four">
                <img src="~/assets/images/shape/183.svg" alt="" class="shapes shape-five">
                <img src="~/assets/images/shape/184.svg" alt="" class="shapes shape-six">
                <img src="~/assets/images/shape/185.svg" alt="" class="shapes shape-seven">
                <img src="~/assets/images/shape/186.svg" alt="" class="shapes shape-eight">
                <img src="~/assets/images/shape/187.svg" alt="" class="shapes shape-nine">
                <img src="~/assets/images/shape/188.svg" alt="" class="shapes shape-ten">

                <!-- <div class="icon-box-one"><img src="~/assets/images/logo/js.png" alt="" /></div>
            <div class="icon-box-two"><img src="~/assets/images/logo/python.png" alt="" /></div>
            <div class="icon-box-three"><img src="~/assets/images/logo/github.png" alt="" /></div>
            <div class="icon-box-four"><img src="~/assets/images/logo/live.png" alt="" /></div>
            <div class="icon-box-five"><img src="~/assets/images/logo/react.png" alt="" /></div>
            <div class="icon-box-six"><img src="~/assets/images/logo/vue.png" alt="" /></div>
            <div class="icon-box-seven"><img src="~/assets/images/logo/ssh.png" alt="" /></div>
            <div class="icon-box-eight"><img src="~/assets/images/logo/docker.png" alt="" /></div> -->
            </div> <!-- /.coming-soon-content -->
        </div> <!-- /.full-height-layout -->

    </div>
</template>

<script setup lang="ts">
useHead({
    title: "Protect Your IDE from Malicious Extensions",
})
</script>